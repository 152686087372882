/* @import url("https://smartermaths.staging.wpengine.com/wp-content/custom/css/custom.css"); */


/* @import url("https://smartermaths.staging.wpengine.com/wp-content/custom/css/shared-edit-and-view.css?v=1.23"); */

/* @import url("https://smcontent.netlify.app/static/smClientResources/style/cmsstyles.css?v=1.01"); */
/* @import url("https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css"); */

/* @import url("https://teacher.smartermaths.com.au/wp-content/custom/css/shared-edit-and-view.css"); */

.mr-table-basic td {
    padding-top: 6px;
    vertical-align: middle;
}

.sm-a-selected {
    background-color: red;
}

.sm-post-content li,
.sm-post-content p {
    margin-bottom: 10px;
}

.sm-post-content ul li {
    margin-left: 40px;
    list-style-type: disc;
}

.accordions .accordion .accordion-header {
    background-color: #3c3838d4
}


/* enhancement for bulma is-loading on divs, etc .. see https://github.com/jgthms/bulma/issues/847 */


/* .element {
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        &:after {
            @include loader;
            position: absolute;
            top: calc(50% - 2.5em);
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }
} */


/* Styles for Analytics / graphs */
.sm-selected {
    background-color: NAVAJOWHITE;
}

/*rect {
//    fill: seagreen;
}*/

.sm-band-null {
    fill: #bbb
}
.sm-band-1 {
    fill: #bbb
}
.sm-band-2 {
    fill: #bbb
}
.sm-band-3 {
    fill: #bbb
}
.sm-band-4 {
    fill: #363636 /*bulma "is-dark"*/
}
.sm-band-5 {
    fill: #00d1b2 /*bulma "is-primary"*/
}
.sm-band-6 {
    fill: #3273dc /*bulma "is-link"*/
}
.sm-band-7 {
    fill: #209cee /*bulma "is-info"*/
}
.sm-band-8 {
    fill: #23d160 /*bulma "is-success"*/
}
.sm-band-9 {
    fill: #ffdd57 /*bulma "is-warning"*/
}
.sm-band-10 {
    fill: #ff3860 /*bulma "is-danger"*/
}



rect:hover {
    /*fill: mediumseagreen;*/
    opacity: .7;
}

.sm-display-none {
    display: none;
}

.sm-elm-table {
    overflow-x: auto;
}

.sm-tour-hover {
    background-color: #9e9e9eb5 !important;
}

#below-elm {
    background-color: rgb(130, 165, 180)
}

#helpAnchorOnPageBackground {
    color: rgb(74, 75, 97);
    font-size: 0.8em;
}

/* As per https://www.w3docs.com/snippets/css/how-to-select-all-child-elements-recursively-in-css.html */
/* div.sm-markdown, */
div.sm-markdown > * {
    padding-bottom: 1em;
}
div.sm-markdown ul {
    list-style: disc;
    list-style-position: inside;
}
div.sm-markdown ol {
    list-style: decimal;
    list-style-position: inside;
}
div.sm-markdown li>p {
    display: inline;
}
div.sm-markdown h1 {
    font-size: 1.5em;
}
div.sm-markdown h2 {
    font-size: 1.3em;
}
div.sm-markdown h3 {
    font-size: 1.1em;
}



@media print {
    .no-print {
	display: none !important;
    }
	
    /* p.bodyText {font-family:georgia, times, serif;} */
}
